exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-getstarted-player-js": () => import("./../../../src/pages/getstarted/player.js" /* webpackChunkName: "component---src-pages-getstarted-player-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-2018-04-press-release-index-js": () => import("./../../../src/pages/news/2018-04-press-release/index.js" /* webpackChunkName: "component---src-pages-news-2018-04-press-release-index-js" */),
  "component---src-pages-news-2019-07-press-release-index-js": () => import("./../../../src/pages/news/2019-07-press-release/index.js" /* webpackChunkName: "component---src-pages-news-2019-07-press-release-index-js" */),
  "component---src-pages-news-2020-02-press-release-index-js": () => import("./../../../src/pages/news/2020-02-press-release/index.js" /* webpackChunkName: "component---src-pages-news-2020-02-press-release-index-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-overview-js": () => import("./../../../src/pages/overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-partnership-veo-js": () => import("./../../../src/pages/partnership/veo.js" /* webpackChunkName: "component---src-pages-partnership-veo-js" */),
  "component---src-pages-players-js": () => import("./../../../src/pages/players.js" /* webpackChunkName: "component---src-pages-players-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-uscsdemos-js": () => import("./../../../src/pages/uscsdemos.js" /* webpackChunkName: "component---src-pages-uscsdemos-js" */)
}

